'use strict';

// Убирает фокус с элементов если активна мышка
(function () {
  // Let the document know when the mouse is being used
  document.body.addEventListener('mousedown', function () {
    document.body.classList.add('using-mouse');
  });

  // Re-enable focus styling when Tab is pressed
  document.body.addEventListener('keydown', function (event) {
    if (event.keyCode === 9) {
      document.body.classList.remove('using-mouse');
    }
  });
})();

// Зацикливание фокуса для доступности в модалках
(function () {
  const
    MODAL_ELEMENT_FOCUSED = window.utils.focusedElement,
    MODAL_FOCUS_TRAP_CLASS_NAME = 'js-trap-focus';

  function setFocusTrap(focusWrap) {
    let
      focusedElements = focusWrap.querySelectorAll(MODAL_ELEMENT_FOCUSED),
      firstFocusElement = focusedElements[0],
      lastFocusElement = focusedElements[focusedElements.length - 1],
      defaultFocusElements = focusWrap.querySelectorAll('.' + MODAL_FOCUS_TRAP_CLASS_NAME);

    if (defaultFocusElements.length === 0 && focusedElements.length !== 0) {
      let
        createFocusTrap = window.utils.createDivWithClass(MODAL_FOCUS_TRAP_CLASS_NAME),
        cloneCreateFocusTrap = createFocusTrap.cloneNode();

      cloneCreateFocusTrap.setAttribute('tabindex', '0');
      createFocusTrap.setAttribute('tabindex', '0');

      focusWrap.appendChild(createFocusTrap);
      focusWrap.insertAdjacentElement('afterbegin', cloneCreateFocusTrap);

      // Зацикливание фокуса внутри модалки
      let focusTraps = focusWrap.children;

      if (!lastFocusElement) {
        lastFocusElement = focusTraps[focusTraps.length - 1];
      }
      if (!firstFocusElement) {
        firstFocusElement = focusTraps[0];
      }

      firstFocusElement.focus();

      focusTraps[focusTraps.length - 1].addEventListener('focus', function () {
        firstFocusElement.focus();
      });

      focusTraps[0].addEventListener('focus', function () {
        lastFocusElement.focus();
      });
    } else {
      defaultFocusElements.forEach(function (current) {
        current.remove();
      })
    }

  }

  window.focusTrap = {
    setFocusTrap: setFocusTrap
  }

})();

// Добавление затемнения под модалки
(function () {
  const OVERLAY_CLASS_NAME = 'modal-overlay';

  let createOverModal = window.utils.createDivWithClass(OVERLAY_CLASS_NAME);

  let addOverlayWindow = function (time) {
    document.body.appendChild(createOverModal);

    let modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

    setTimeout(function () {
      modalOverlay.classList.add('this-show');
    }, time)
  };

  let removeOverlayWindow = function (time) {
    let
      duration = (time) ? time : 0,
      modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

    modalOverlay.classList.remove('this-show');

    setTimeout(function () {
      modalOverlay.remove();
    }, duration)
  };

  window.overlay = {
    add: addOverlayWindow,
    remove: removeOverlayWindow
  }

})();

// Модальное окно
(function () {
  const
    MODAL_BUTTON_CLOSE_CLASS_NAME = 'js-modal-close',
    TRANSITION_TIME = 0.4;

  let alexModal = function (modal) {
    let
      buttonModalShow = modal,
      modalWindow = document.querySelector(buttonModalShow.dataset.id),
      buttonModalHide = modalWindow.querySelectorAll('.' + MODAL_BUTTON_CLOSE_CLASS_NAME),
      body = document.body;

    function openModal() {
      let scrollBarWidth = window.utils.getScrollbarWidth();

      body.classList.add('modal-open');

      document.documentElement.style.setProperty('--scroll-width', scrollBarWidth + 'px');
      modalWindow.style.paddingRight = scrollBarWidth + 'px';

      modalWindow.style.display = 'block';
      setTimeout(function () {
        modalWindow.classList.remove('modal-hidden');
      }, 100);

      window.overlay.add(100);

      document.addEventListener('keydown', documentKeyDownHandler);
      modalWindow.addEventListener('click', modalWindowClickHandler);

      // Доступность для модалк
      modalWindow.removeAttribute('aria-hidden');

      window.focusTrap.setFocusTrap(modalWindow);

    }

    function closeModal() {
      modalWindow.classList.add('modal-hidden');

      // Вешаю отсчёт времени для отработки CSS анимации
      setTimeout(function () {

        body.classList.remove('modal-open');
        document.documentElement.style.setProperty('--scroll-width', '0');

        modalWindow.style.display = 'none';
        modalWindow.style.paddingRight = '0';
      }, TRANSITION_TIME * 1000);

      window.overlay.remove(TRANSITION_TIME * 1000);

      // Доступность для модалк
      modalWindow.setAttribute('aria-hidden', 'true');

      // Убираю все события после закрытия модалки
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.removeEventListener('click', closeModal);
        });
      }
      modalWindow.removeEventListener('click', modalWindowClickHandler);
      document.removeEventListener('keydown', documentKeyDownHandler);

      window.focusTrap.setFocusTrap(modalWindow);

      buttonModalShow.focus();
    }

    function documentKeyDownHandler(evt) {
      if (evt.key === window.utils.keyEscape && !evt.target.matches('input, textarea, select')) {
        closeModal();
        // console.log(evt);
      }
    }

    function modalWindowClickHandler(evt) {
      if (evt.target === modalWindow) {
        closeModal();
      }
    }

    buttonModalShow.addEventListener('click', function (evt) {
      evt.preventDefault();
      openModal();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.addEventListener('click', closeModal);
        });
      }
    });

    buttonModalShow.addEventListener('keydown', function (evt) {
      if (evt.key === window.utils.keyEnter && !buttonModalShow.matches('button, a')) {
        evt.preventDefault();
        openModal();
        if (buttonModalHide) {
          buttonModalHide.forEach(function (current) {
            current.addEventListener('click', closeModal);
          });
        }
      }
    });

    if (modalWindow.classList.contains('open')) {
      openModal();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.addEventListener('click', closeModal);
        });
      }
    }
  };

  window.modal = {
    alexModal: alexModal
  }

})();

// Раскрывающиеся блоки
(function () {
  const
    WINDOW_BUTTON_CLOSE_CLASS_NAME = 'js-expanded-close',
    OVERLAY_CLASS_NAME = 'expanded-overlay',
    BUTTON_EXPANDED_CLASS_NAME = 'button-expand',
    BOX_EXPANDED_CLASS_NAME = 'box-expand',
    TRANSITION_DURATION = 300;

  let createOverModal = window.utils.createDivWithClass(OVERLAY_CLASS_NAME);

  document.body.appendChild(createOverModal);

  let alexExpanded = function (expanded) {
    let
      buttonExpandedShow = expanded,
      expandWindow = document.querySelector(buttonExpandedShow.dataset.id),
      buttonExpandedHide = expandWindow.querySelectorAll('.' + WINDOW_BUTTON_CLOSE_CLASS_NAME),
      ariaLabel = buttonExpandedShow.hasAttribute('aria-label'),
      closeText = buttonExpandedShow.getAttribute('aria-label'),
      openText = buttonExpandedShow.dataset.ariaLabel;

    function expandedExpand() {
      // Добавляю классы
      expandWindow.classList.add(BOX_EXPANDED_CLASS_NAME);
      buttonExpandedShow.classList.add(BUTTON_EXPANDED_CLASS_NAME);

      // Событие на окне для кнопки ESCAPE
      document.addEventListener('keydown', documentKeyDownHandler);

      // Доступность
      expandWindow.setAttribute('aria-expanded', 'false');

      if (ariaLabel) {
        buttonExpandedShow.setAttribute('aria-label', openText);
      }

      // Зацикливание фокуса
      // window.focusTrap.setFocusTrap(expandWindow);
      if (expandWindow.querySelectorAll(window.utils.focusedElement).length > 0) {
        setTimeout(function () {
          expandWindow.querySelectorAll(window.utils.focusedElement)[0].focus();
        }, TRANSITION_DURATION);
      }

      let modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

      // Проверка на количество открытых элементов
      if (document.querySelectorAll('.' + BOX_EXPANDED_CLASS_NAME).length > 0) {
        // Добавление класса к затемнению
        modalOverlay.classList.add('this-show');
      }

      // Событие клика на фоне
      modalOverlay.addEventListener('click', expandedCollapse);

      buttonExpandedShow.removeEventListener('click', buttonExpandedShowClickHandler);
      buttonExpandedShow.addEventListener('click', expandedCollapse);
    }

    function expandedCollapse() {
      // Удаление классов
      expandWindow.classList.remove(BOX_EXPANDED_CLASS_NAME);
      buttonExpandedShow.classList.remove(BUTTON_EXPANDED_CLASS_NAME);

      let modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

      // Проверка на количество открытых элементов
      if (document.querySelectorAll('.' + BOX_EXPANDED_CLASS_NAME).length === 0) {
        // Удаление класса c затемнения
        modalOverlay.classList.remove('this-show');
      }

      // Удаление события клика на фоне
      modalOverlay.removeEventListener('click', expandedCollapse);

      // Доступность
      expandWindow.setAttribute('aria-expanded', 'true');

      if (ariaLabel) {
        buttonExpandedShow.setAttribute('aria-label', closeText);
      }

      // Убираю все события с кнопки закрытия
      if (buttonExpandedHide) {
        buttonExpandedHide.forEach(function (current) {
          current.removeEventListener('click', expandedCollapse);
        });
      }

      // Удаление события на окне для кнопки ESCAPE
      document.removeEventListener('keydown', documentKeyDownHandler);

      // Возврат фокуса на кнопку открытия
      buttonExpandedShow.focus();

      buttonExpandedShow.removeEventListener('click', expandedCollapse);
      buttonExpandedShow.addEventListener('click', buttonExpandedShowClickHandler);
    }

    function documentKeyDownHandler(evt) {
      if (evt.key === window.utils.keyEscape && !evt.target.matches('input, textarea, select')) {
        expandedCollapse();
      }
    }

    function buttonExpandedShowClickHandler(evt) {
      // Вешает preventDefault если элемент ссылка
      if (buttonExpandedShow.tagName === 'A') {
        evt.preventDefault();
      }

      expandedExpand();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonExpandedHide) {
        buttonExpandedHide.forEach(function (current) {
          current.addEventListener('click', expandedCollapse);
        });
      }
    }

    buttonExpandedShow.addEventListener('click', buttonExpandedShowClickHandler);

    buttonExpandedShow.addEventListener('keydown', function (evt) {
      if (evt.key === window.utils.keyEnter && !buttonExpandedShow.matches('button, a')) {
        // Вешает preventDefault если элемент ссылка
        if (buttonExpandedShow.tagName === 'A') {
          evt.preventDefault();
        }

        expandedExpand();
        if (buttonExpandedHide) {
          buttonExpandedHide.forEach(function (current) {
            current.addEventListener('click', expandedCollapse);
          });
        }
      }
    });

    if (expandWindow.classList.contains('open')) {
      expandedExpand();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonExpandedHide) {
        buttonExpandedHide.forEach(function (current) {
          current.addEventListener('click', expandedCollapse);
        });
      }
    }
  };

  window.expanded = {
    expand: alexExpanded
  }
})();

(function () {

  if (document.querySelector('.js-aside')) {
    let
      aside = document.querySelector('.js-aside'),
      asideButton = aside.querySelector('.js-aside-hide'),
      targetText = asideButton.querySelector('.js-aside-hide-text'),
      openText = targetText.textContent,
      closeText = asideButton.dataset.altText;

    asideButton.addEventListener('click', function (evt) {
      evt.preventDefault();

      document.body.classList.toggle('aside-hide');
      targetText.textContent = closeText;

      asideButton.addEventListener('click', asideButtonClickHandler)
    });

    function asideButtonClickHandler() {
      targetText.textContent = openText;

      asideButton.removeEventListener('click', asideButtonClickHandler)
    }
  }
})();

// Переключение табов
(function () {
  let tabWork = function (tabParent) {
    let
      tabArray = window.utils.getArrayFrom(tabParent.querySelector('.js-tab-item').children),
      tabTargetArray = window.utils.getArrayFrom(tabParent.querySelector('.js-tab-target').children),
      tabActive = tabParent.querySelector('.js-tab-item .active');

    let calculateHeight = function () {
      let targetActive = tabParent.querySelector('.js-tab-target .active');

      setTimeout(function () {
        tabParent.querySelector('.js-tab-target').style.minHeight = targetActive.offsetHeight + 'px';
      }, 500);
    };

    calculateHeight();

    let tabIndicator = window.utils.createDivWithClass('tab-indicator');
    tabParent.querySelector('.js-tab-item').appendChild(tabIndicator);

    let calculateYAxisIndicator = function () {
      tabIndicator.style.transform = 'translateX(' + tabActive.offsetLeft + 'px)';
      tabIndicator.style.width = tabActive.clientWidth + "px";
    };
    calculateYAxisIndicator();

    function toggleTabEvent(current, index) {
      let activeArray = Array.from(tabParent.querySelectorAll('.active'));

      activeArray.forEach(function (activeCurrent) {
        activeCurrent.classList.remove('active');
      });

      current.classList.add('active');
      tabTargetArray[index].classList.add('active');

      tabActive = current;
      calculateYAxisIndicator();
      calculateHeight();
    }

    tabArray.forEach(function (current, index) {
      current.addEventListener('click', function () {
        toggleTabEvent(current, index);
      });

      current.addEventListener('keydown', function (evt) {
        if (evt.key === window.utils.keyEnter || evt.key === window.utils.keySpace) {
          toggleTabEvent(current, index);
        }
      });
    });
  };

  let tabRemove = function (tabParent) {
    tabParent.querySelector('.tab-indicator').remove();
  };

  window.tab = {
    tabWork: tabWork,
    tabRemove: tabRemove
  }

})();

// Календарь отправки
(function () {
  let inputDate = $('.input-daterange');

  if (inputDate.length) {
    !function (a) {
      a.fn.datepicker.dates["uz-latn"] = {
        days: ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba"],
        daysShort: ["Yak", "Du", "Se", "Chor", "Pay", "Ju", "Sha"],
        daysMin: ["Ya", "Du", "Se", "Cho", "Pa", "Ju", "Sha"],
        months: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentabr", "Oktabr", "Noyabr", "Dekabr"],
        monthsShort: ["Yan", "Fev", "Mar", "Apr", "May", "Iyn", "Iyl", "Avg", "Sen", "Okt", "Noy", "Dek"],
        today: "Bugun",
        clear: "O'chirish",
        format: "dd.mm.yyyy",
        weekStart: 1,
        monthsTitle: "Oylar"
      }
    }(jQuery);

    !function (a) {
      a.fn.datepicker.dates.ru = {
        days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
        daysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб"],
        daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
        today: "Сегодня",
        clear: "Очистить",
        format: "dd.mm.yyyy",
        weekStart: 1,
        monthsTitle: "Месяцы"
      }
    }(jQuery);

    inputDate.datepicker({
      format: "dd MM yyyy",
      todayHighlight: true,
      endDate: "today",
      language: window.datepicker.currentLang,
      datesDisabled: window.datepicker.datesDisabled,
      autoclose: true
    });
  }

  /*let changeDateHandler = function (e) {
    document.querySelector(e.target.dataset.hidden).value = moment(e.date).format('DD.MM.YYYY');
  };*/

  // inputDate.find('input').on('changeDate', window.inputFocus.inputFocusCheck);
})();

// Дефолтный поток
(function () {
  if (document.querySelectorAll('.js-modal').length > 0) {
    document.querySelectorAll('.js-modal').forEach(function (current) {
      window.modal.alexModal(current);
    });
  }

  if (document.querySelectorAll('.js-expanded').length > 0) {
    document.querySelectorAll('.js-expanded').forEach(function (current) {
      window.expanded.expand(current);
    });
  }

  document.querySelectorAll('.js-tab-parent').forEach(function (current) {
    window.tab.tabWork(current);
  });
})();


(function () {
  if (window.matchMedia("(max-width: 768px)").matches && document.querySelector('.js-aside-hide')) {
    let asideButton = document.querySelector('.js-aside-hide');

    asideButton.dispatchEvent(new Event('click'));
  }
})();

// Input Mask
(() => {
  window.mm = (i, mask) => {
    const d = {0: "[0-9]", a: "[a-z]"}, msk = mask.split("");
    i.addEventListener("input", handler);
    i.addEventListener("focus", handler);

    function handler(e) {
      if (e.type === "focus" && i.value !== "") return;
      let mskd = [],
        s = i.selectionStart - 1;
      msk.forEach((el, n) => {
        if (d[el]) {
          let t = new RegExp(d[el], "i").test(i.value.charAt(n));
          mskd.push(t ? i.value.charAt(n) : "_");
          if (t && s === n && i.value.charAt(n) !== "_") {
            s++;
          }
        } else {
          mskd.push(el);
          if (s === n) s++;
        }
      });
      i.value = mskd.join("");
      i.selectionStart = i.selectionEnd = s < 0 ? 0 : s;
      setTimeout(function () {
        i.selectionStart = i.selectionEnd = s < 0 ? 0 : s;
      }, 0);
    }
  };

  window.addEventListener('DOMContentLoaded', function () {

    let inputMask = document.querySelectorAll(`[data-mask]`);

    if (inputMask) {
      inputMask.forEach(function (current) {
        window.mm(current, current.dataset.mask);
      });
    }
  });

  // let imask = document.querySelectorAll('.js-inputmask');

  // window.mm(imask, "+998 (00) 000 0 99")
})();


// Вывод сообщения о событии
(function () {
  const ERROR_COLOR = 'orangeRed';
  const SUCCESS_COLOR = '#1cb34d';
  const ERROR_SHOW_TIME = 6000;
  const TRANSITION_DURATION = 0.5;

  // Немедленное закрытие окна
  let closeMessage = function (block, isSuccess) {
    let
      colorSvg = (isSuccess) ? SUCCESS_COLOR : ERROR_COLOR,
      closeButton = document.createElement('button');

    closeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="' + colorSvg + '"><path d="M512 60.3L451.7 0 256 195.7 60.3 0 0 60.3 195.7 256 0 451.7 60.3 512 256 316.3 451.7 512l60.3-60.3L316.3 256 512 60.3z"/></svg>';
    closeButton.classList.add('close-message-popup');

    let closeButtonHandler = function (evt) {
      evt.preventDefault();

      hideMessage(block);

      closeButton.removeEventListener('click', closeButtonHandler);
    };

    closeButton.addEventListener('click', closeButtonHandler);

    block.appendChild(closeButton);

  };

  // Анимация закрытия окна в одельной функции
  let hideMessage = function (block) {
    block.style.top = '-50%';

    setTimeout(function () {
      block.remove();
    }, TRANSITION_DURATION * 1000);
  };

  // Закрытие окна с задержкой
  let hideTimeoutMessage = function (block, isSuccess) {
    let colorTimerBlock = (isSuccess) ? SUCCESS_COLOR : ERROR_COLOR;
    let timerBlock = document.createElement('div');
    let pointOfStart = 100; // Длина визуальной линии с которой начинается остчёт

    timerBlock.setAttribute('style', 'position:absolute; left:0; top:0; height:4px; width:100%; background-color: ' + colorTimerBlock + ';transition: width 0.1s linear');

    let interval = setInterval(function () {
      pointOfStart--;

      timerBlock.style.width = pointOfStart + '%';
    }, (ERROR_SHOW_TIME - 200) / pointOfStart);

    setTimeout(function () {
      clearInterval(interval);

      hideMessage(block);

    }, ERROR_SHOW_TIME);

    block.appendChild(timerBlock);
  };

  // Основная функция показа окна с сообщением
  let showMessage = function (message, isSuccess) {
    let colorMessage = (isSuccess) ? SUCCESS_COLOR : ERROR_COLOR;
    let wrapBlockError = document.createElement('section');
    let titleErrorMessage = document.createElement('h2');
    let textErrorMessage = document.createElement('p');

    wrapBlockError.classList.add('show-popup-message');

    wrapBlockError.setAttribute('style', 'top: -50%; color: ' + colorMessage + '; transition: all ' + TRANSITION_DURATION + 's cubic-bezier(.25,-0.5,0,1.25);');

    // titleErrorMessage.setAttribute('style', 'text-shadow: none;');
    // textErrorMessage.setAttribute('style', 'margin-bottom: 0;');

    titleErrorMessage.textContent = message.title;
    textErrorMessage.textContent = message.text;

    if (message.title) {
      wrapBlockError.appendChild(titleErrorMessage);
    }

    if (message.text) {
      wrapBlockError.appendChild(textErrorMessage);
    }

    document.body.appendChild(wrapBlockError);

    setTimeout(function () {
      wrapBlockError.style.top = '40px';
    }, 100);

    hideTimeoutMessage(wrapBlockError, isSuccess);
    closeMessage(wrapBlockError, isSuccess);
  };

  // Вынос функции в глобальную область видимости
  window.message = {
    showMessage: showMessage
  };

})();

// Обёртка для таблиц
(function () {
  let table = $('.cabinet-table');

  if (table.length > 0) {
    table.each(function () {
      $(this).wrap('<div class="table-overflow"></div>');
    })
  }
})();

(() => {
  let nextButton = document.querySelector('.js-button-next');

  if (nextButton) {
    nextButton.addEventListener('click', (evt) => {
      let
        target = evt.currentTarget,
        targetID = target.dataset.target;

      document.querySelector(targetID).classList.add('next-show');
    })
  }
})();

//  Раскрывает выпадающий блок
(function () {
  /**
   * @param  {Object} path             Путь до елемента с которого всё начинается (стрелка)
   * @param  {String} parent_name      Класс родителя откуда от места начала (".exampleClass")
   * @param  {String} children_in      Класс скрытого блока (".exampleClass")
   * @param  {String | Number} duration_h  Время исполнения анимации (slow|normal|fast)
   * @param  {Boolean} hidden_path     Скрывать path элемент (true) или нет (false)
   * @param  {Number} a_time           Задаёт задержку раскрытия в милисекундах
   * @return {undefined}
   */
  function wiyzjalka(path, parent_name, children_in, duration_h, hidden_path, a_time) {
    var a_time = (!a_time) ? 0 : a_time;
    path.each(function () {
      var thisHand = $(this);

      if ($(this).parents(parent_name).find(children_in).find(".js-active").length > 0 || $(this).hasClass('js-open')) {
        $(this).parents(parent_name).addClass("js-open");
        $(this).parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h);
        if ("true" === $(this).parents(parent_name).find(children_in).attr('aria-hidden')) {
          $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'false');
        } else {
          $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'true');
        }
      }

      function openPandora() {
        if ($(children_in).find('.hide-box').length) {
          let dMSec = (duration_h === "slow") ? 600
            : (duration_h === "normal") ? 400
              : (duration_h === "fast") ? 200 : 0
        }
        if (hidden_path) {
          thisHand.hide();
        }

        path.parents(parent_name).find(children_in).stop(true, false).slideUp(duration_h);
        path.parents(parent_name).removeClass("js-open");
        path.parents(parent_name).removeClass("js-active");
        if (thisHand.parents(parent_name).find(children_in).is(children_in)) {
          if (thisHand.parents(parent_name).find(children_in).is(":visible")) {
            thisHand.parents(parent_name).removeClass("js-open");
            thisHand.parents(parent_name).find(children_in).stop().slideUp(duration_h, function () {
              // console.log($(this).height());
              $(this).css("height", 'auto');
            });
            if (thisHand.parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'false');
            } else {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'true');
            }
          } else {
            if (thisHand.parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'false');
            } else {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'true');
            }
            thisHand.parents(parent_name).addClass("js-open");
            thisHand.parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h, function () {
              console.log($(this).height());
              // $(this).css("height", "unset");
            });
          }
        }
      }

      thisHand.bind("click", openPandora);
      thisHand.on("keydown", function (e) {
        if (e.keyCode === 13) {
          openPandora()
        }
      });
    });
  }

  window.collapse = {
    wiyzjalka: wiyzjalka
  }
})();

(() => {
  if ($('.js-pandora-parent').length) {
    window.collapse.wiyzjalka($('.js-pandora-hand'), '.js-pandora-parent', '.js-pandora-box', 'normal', false, 0);
  }
})();


// (() => {
//   const FILE_UPLOAD_EXT = ['jpg', 'png', 'gif'];
//
//   let documentFiles = document.querySelectorAll('.js-upload-files');
//
//   documentFiles.forEach((c) => {
//     let inputFile = c.querySelector(`.js-file`);
//
//     inputFile.addEventListener('change', (e) => {
//       e.preventDefault();
//
//       let
//         isFileImage = false,
//         file = inputFile.files[0];
//
//       // Валидация на тип файла
//       if (file) {
//         let fileName = file.name.toLowerCase();
//
//         isFileImage = FILE_UPLOAD_EXT.some(function (current) {
//           return fileName.endsWith(current);
//         });
//       }
//
//       if (isFileImage) {
//         // Подготовка шаблона для загружаемых картинок
//         let fileInner = `
//             <img class="js-img-upload" src="" width="84" height="84" />
//             <a class="file-list__link file-list__link--left" href="#" data-fancybox="${inputFile.id}" title="Увеличить картинку">
//               <svg width="18" height="18" fill="white" aria-hidden="true" focusable="false">
//                 <use xlink:href="img/svg/sprite.svg#icon-eye"></use>
//               </svg>
//             </a>
//             <button type="button" onclick="this.parentNode.remove()" class="file-list__link file-list__link--right" title="Удалить файл">
//               <svg width="16" height="16" fill="white" aria-hidden="true" focusable="false">
//                 <use xlink:href="img/svg/sprite.svg#icon-trash"></use>
//               </svg>
//             </button>
//           `;
//         let fileWrap = document.createElement(`li`);
//
//         fileWrap.classList.add(`file-list__doc`);
//
//         fileWrap.innerHTML = (fileInner);
//
//
//         // Чтение файла и загрузка его в виде dataURL
//         let fileReader = new FileReader();
//         fileReader.addEventListener(`load`, function (fileReadEvt) {
//           c.querySelector(`.js-upload-list`).prepend(fileWrap);
//
//           let imgElement = c.querySelector(`.js-img-upload`);
//
//           imgElement.src = fileReadEvt.target.result;
//           imgElement.nextElementSibling.href = fileReadEvt.target.result;
//         });
//         fileReader.readAsDataURL(file);
//       }
//     });
//   });
// })();
